<script>
import { authMethods } from "@/state/helpers";
import { required, email } from "vuelidate/lib/validators";
import { SEND_RESET_PASSWORD_LINK } from "@/state/actions.type";

export default {
  data() {
    return {
      email: "",
      submitted: false,
      error: null,
      tryingToReset: false,
      linkSentSuccess: false,
      linkSentFailure: false,
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    }
  },
  validations: {
    email: { required, email }
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  methods: {
    ...authMethods,
    // Try to register the user in with the email, fullname
    // and password they provided.
    sendResetLink() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        this.tryingToReset = true;
        // Reset the authError if it existed.
        this.error = null;
        return (
          this.$store
            .dispatch(`auth/${SEND_RESET_PASSWORD_LINK}`, {
              email: this.email,
            })
            // eslint-disable-next-line no-unused-vars
            .then(response => {
              this.tryingToReset = false;
              this.linkSentSuccess = true;
            })
            .catch(error => {
              this.tryingToReset = false;
              this.error = error ? error : "";
              this.linkSentFailure = true;
            })
        );
      }
    },

    resetNotificationError() {
      this.$store.dispatch('notification/clear');
    }
  },

  beforeDestroy() {
    this.$store.dispatch('notification/clear');
  }
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img src="@/assets/images/portone.png" height="30" alt="logo" />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">Reset Password</h4>
                        <p class="text-muted">Enter the email associated with your account and we will send you a link to reset your password.</p>
                      </div>

                      <div class="p-2 mt-5">
                        <b-alert
                          v-model="linkSentFailure"
                          class="mb-4"
                          variant="danger"
                          dismissible
                        >{{ error }}</b-alert>

                        <b-alert
                          v-model="linkSentSuccess"
                          class="mt-3"
                          variant="success"
                          dismissible
                        >{{ notification.message }}</b-alert>

                        <form class="form-horizontal" @submit.prevent="sendResetLink">
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="useremail">Email</label>
                            <input
                              v-model="email"
                              type="email"
                              class="form-control"
                              id="useremail"
                              placeholder="Enter email"
                              :class="{ 'is-invalid': submitted && $v.email.$error }"
                            />
                            <div v-if="submitted && $v.email.$error" class="invalid-feedback">
                              <span v-if="!$v.email.required">Email is required.</span>
                              <span v-if="!$v.email.email">Please enter valid email.</span>
                            </div>
                          </div>

                          <div class="mt-4 text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >Continue</button>
                          </div>
                        </form>
                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          Already have an account ?
                          <router-link
                            to="/login"
                            class="font-weight-medium text-primary"
                          >Login</router-link>
                        </p>
                        <p>
                          Don't have an account ?
                          <router-link
                            to="/register"
                            class="font-weight-medium text-primary"
                          >Register</router-link>
                        </p>
                        <p>
							© <a href="https://portone.io/global/en" class="text-primary">PortOne SG Pte. Ltd</a>
                          <!-- Crafted with <i class="mdi mdi-heart text-danger"></i> -->
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <!-- <div class="bg-overlay"></div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
